<template>
  <div class="page container">
    <a-row class="mb-2">
      <a-col :xs="24">
        <h2>Get in touch</h2>
      </a-col>
      <a-col :xs="24" :md="14">
        <p>
          Let’s have a talk.<br /><br />

          Tell us what you need by using the form below to drop us an email. We
          also accept old-fashioned phone calls on 033 342 2766.
        </p>
      </a-col>
      <a-col :xs="24" :md="14">
        <a-form :model="form" :layout="inline">
          <a-form-item>
            <label>Name</label>
            <a-input v-model:value="form.contactName"> </a-input>
          </a-form-item>
          <a-form-item>
            <label>Email</label>
            <a-input v-model:value="form.contactEmail" type="email"> </a-input>
          </a-form-item>
          <a-form-item>
            <label>Contact Number</label>
            <a-input v-model:value="form.contactPhone" type="tel"> </a-input>
          </a-form-item>
        </a-form>
        <a-form :model="form">
          <a-form-item>
            <label>Subject</label>
            <a-input v-model:value="form.contactSubject"> </a-input>
          </a-form-item>
        </a-form>
        <a-form :model="form">
          <a-form-item>
            <label>Message</label>
            <a-textarea v-model:value="form.contactMessage" allow-clear />
          </a-form-item>
        </a-form>
        <a-button @click="submit()">Send</a-button>
      </a-col>
    </a-row>
  </div>
</template>


<script>
import axios from "axios";
import { notification } from "ant-design-vue";

export default {
  data() {
    return {
      form: {},
    };
  },
  inject: {
    api: {
      from: "APIURL",
    },
  },
  methods: {
    submit() {
      axios
        .post(`${this.api}/Contact/`, this.form)
        .then(function () {
          notification.open({
            message: "Your message has been submitted.",
            placement: "bottomRight",
          });
        })
        .catch(function () {
          notification.open({
            message: "There was an error submitting your message.",
            placement: "bottomRight",
          });
        });
    },
  },
};
</script>