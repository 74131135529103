<template>
  <div style="background: #E06B94; padding-top: 50px; padding-bottom: 50px; margin-top: 32px;">
    <div class="container footer">    
      <a-row :gutter="{ xs: 8, sm: 10, md: 24, lg: 32 }">
        <a-col :xs="24" :md="12" :lg="8">
          <h2 class="col-heading">COPYRIGHT</h2> 
            <p style="white-space: pre-line;">&copy; Viv Greene Attorneys 2022</p>
        </a-col>
        <a-col :xs="12" :md="4" :lg="4">
          <h2 class="col-heading">LINKS LIST</h2> 
          <ul class="nav flex-column">
            <li class="nav-item">
              <a href="https://www.vglaw.co.za/" target="_self" class="nav-link">Visit VGLaw Website</a>
            </li>
          </ul>
        </a-col>
        <a-col :xs="12" :md="8" :lg="6">
          <h2 class="col-heading">CONTACT</h2>
          
          <div class="icon-text">
            <i class="fas fa-phone"></i>
            <p>033 342 2766</p>
          </div>
          
          <div class="icon-text">
            <i class="far fa-envelope"></i>
            <p>popia@vglaw.co.za</p>
          </div>
        </a-col>        

        <a-col :xs="24" :md="6" :lg="4">
          <img src="../assets/images/Logo.png" style="width: 200px" />
        </a-col>
            
      </a-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
